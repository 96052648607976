<template>
  <div>
    <pageHeader />

    <div class="basicInfo">
      <v-container>
        <v-row>
          <v-col>
            <div class="head">
              <h2 class="main-title mb-3">البيانات الاساسية</h2>
              <p class="d-flex align-center">
                <v-icon color="#2D3633" small>mdi-checkbox-blank-circle</v-icon>
                <span class="line"></span>
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="4" class="d-none d-sm-none d-md-block"></v-col>
          <v-col
            cols="12"
            sm="12"
            md="8"
            class="pa-0 fields mt-5"
            v-if="
              updateBasicInfo == false &&
              updateMobile == false &&
              updatePassword == false
            "
          >
            <v-row>
              <v-col cols="12" sm="6">
                <div class="field d-flex justify-space-around mb-8">
                  <label for="name" class="user-main-color"
                    ><strong>الاسم</strong>
                  </label>
                  <p class="text mb-0">{{ basicInfo.name }}</p>
                </div>
                <div class="field d-flex justify-space-around mb-8">
                  <label for="name" class="user-main-color"
                    ><strong>البريد الالكترونى</strong>
                  </label>
                  <p class="text mb-0">{{ basicInfo.email }}</p>
                </div>
                <div class="d-block d-sm-none text-center">
                  <div class="update">
                    <v-btn class="main-btn" large @click="makeUpdateBasicInfo"
                      >تعديل</v-btn
                    >
                  </div>
                </div>
                <div class="field d-flex justify-space-around mb-8">
                  <label for="name" class="user-main-color"
                    ><strong>رقم الجوال</strong>
                  </label>
                  <p class="text mb-0">
                    <span class="mobile-num">
                      +{{ basicInfo.country_code }} {{ basicInfo.mobile }}
                    </span>
                    <v-btn icon class="text-center" @click="openUpdateMobile">
                      <v-icon class="mr-5" color="#2D3633">mdi-pencil</v-icon>
                    </v-btn>
                  </p>
                </div>
                <div class="field d-flex justify-space-around mb-8">
                  <label for="name" class="user-main-color"
                    ><strong>كلمة المرور</strong>
                  </label>
                  <p class="text mb-0">
                    ***********
                    <v-btn icon class="text-center" @click="openUpdatePassword">
                      <v-icon class="mr-5" color="#2D3633">mdi-pencil</v-icon>
                    </v-btn>
                  </p>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="text-center d-none d-sm-block">
                <div class="update">
                  <v-btn
                    class="white--text update-btn"
                    color="#a53860"
                    large
                    @click="makeUpdateBasicInfo"
                    >تعديل</v-btn
                  >
                </div>

                <p class="text-center error--text" v-if="attachImgError">
                  {{ attachImgError }}
                </p>
                <div class="profile-img">
                  <v-btn
                    icon
                    @click.prevent="deleteDialog = true"
                    class="top-icon"
                    v-if="basicInfo.image"
                  >
                    <v-icon color="#2D3633">mdi-close</v-icon>
                  </v-btn>
                  <div class="img">
                    <v-icon v-if="!basicInfo.image" large>mdi-account</v-icon>
                    <img v-else :src="basicInfo.image" alt="profile-image" />
                  </div>

                  <v-btn
                    icon
                    class="bottom-icon"
                    @click="profileSelectFile"
                    :loading="loading"
                  >
                    <v-icon color="#2D3633">mdi-pencil</v-icon>
                  </v-btn>

                  <v-file-input
                    style="display: none"
                    id="avatar"
                    @change="onAddFile"
                    accept="image/*"
                  ></v-file-input>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <!-- ================== update baseic info ===================== -->
          <v-col
            cols="12"
            md="8"
            class="edit-mode mt-5"
            v-if="updateBasicInfo == true"
          >
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="12" v-if="validationError">
                  <p class="text-center mb-8 error--text">
                    {{ validationError }}
                  </p>
                </v-col>
                <v-col cols="12" sm="3">
                  <label for="name" class="user-main-color">الاسم</label></v-col
                >
                <v-col cols="12" sm="6">
                  <v-text-field
                    placeholder="الاسم"
                    v-model="basicInfo.name"
                    :rules="[validationRules.required]"
                    outlined
                    dense
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="2" class="d-none d-sm-block">
                  <v-btn
                    class="white--text main-btn"
                    color="#a53860"
                    large
                    @click.prevent="updateInfo"
                    >حفظ</v-btn
                  ></v-col
                >
              </v-row>

              <v-row>
                <v-col cols="12" sm="3"
                  ><label for="name" class="user-main-color"
                    >البريد الالكترونى</label
                  ></v-col
                >
                <v-col cols="12" sm="6">
                  <v-text-field
                    placeholder="البريد الالكترونى"
                    v-model="basicInfo.email"
                    outlined
                    dense
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="2" class="d-none d-sm-block"
                  ><v-btn
                    class="cancel-btn"
                    large
                    @click="cancelUpdateBasicInfo"
                    >الغاء</v-btn
                  ></v-col
                >
              </v-row>

              <v-row>
                <v-col class="text-left d-block d-sm-none">
                  <v-btn
                    class="main-btn mx-3"
                    color="#a53860"
                    large
                    @click.prevent="updateInfo"
                    >حفظ</v-btn
                  >
                  <v-btn class="cancel-btn" large @click="cancelUpdateBasicInfo"
                    >الغاء</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <!-- ================== update Mobile Number ===================== -->
          <v-col
            cols="12"
            md="8"
            class="edit-mode mt-5"
            v-if="updateMobile == true"
          >
            <p class="text-center mb-8 error--text">
              {{ validationError }}
            </p>
            <v-form v-model="valid" ref="form">
              <v-row>
                <v-col cols="12" sm="3">
                  <label for="mobile" class="user-main-color">رقم الجوال</label>
                </v-col>
                <v-col cols="12" sm="7">
                  <v-row class="align-baseline">
                    <v-col cols="6" sm="8" class="pr-0">
                      <v-text-field
                        placeholder="رقم الجوال"
                        v-model="basicInfo.mobile"
                        :rules="[validationRules.required]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" sm="4">
                      <v-autocomplete
                        v-model="basicInfo.country_code_id"
                        label="كود البلد"
                        :items="Codes"
                        required
                        @change="hideLabel = true"
                        :rules="[validationRules.required]"
                        item-text="title"
                        item-value="value"
                        disabled
                        outlined
                        dense
                      >
                        <!-- v-model="editedItem.country_code_id" -->
                        <template slot="selection" slot-scope="data">
                          <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }}-->

                          <v-flex xs3>
                            <v-avatar size="25">
                              <img :src="data.item.icon" />
                            </v-avatar>
                          </v-flex>
                          <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                        </template>
                        <template slot="item" slot-scope="data">
                          <v-list-item-avatar
                            style="width: 20px; min-width: 20px; height: 20px"
                          >
                            <img :src="data.item.icon" />
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="data.item.title"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="align-baseline">
                <v-col cols="12" sm="3">
                  <label for="mobile" class="user-main-color">
                    كلمة المرور</label
                  >
                </v-col>
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="basicInfo.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="password"
                    @click:append="show1 = !show1"
                    :rules="[validationRules.required]"
                    autocomplete="off"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-left" cols="12" sm="10">
                  <v-btn
                    class="main-btn"
                    large
                    @click.prevent="updateMobileInfo()"
                    >تعديل</v-btn
                  >
                  <v-btn
                    class="white--text cancel-btn mx-4"
                    color="#A8A8A8"
                    @click.prevent="cancelUpdateMobile"
                    large
                    >الغاء</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <!-- ================== update password ===================== -->
          <v-col
            cols="12"
            md="8"
            class="pa-sm-0 edit-mode mt-5"
            v-if="updatePassword == true"
          >
            <p class="text-center mb-8 error--text">
              {{ validationError }}
            </p>
            <v-form v-model="valid" ref="form">
              <v-row class="align-baseline">
                <v-col cols="4" sm="3">
                  <label for="mobile" class="user-main-color">
                    كلمة المرور الحالية</label
                  >
                </v-col>
                <v-col cols="8" sm="7">
                  <v-text-field
                    v-model="basicInfo.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="password"
                    @click:append="show1 = !show1"
                    :autocomplete="false"
                    :rules="[
                      validationRules.required,
                      validationRules.spaces,
                      validationRules.password,
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="align-baseline">
                <v-col cols="4" sm="3">
                  <label for="mobile" class="user-main-color">
                    كلمة المرور الجديدة</label
                  >
                </v-col>
                <v-col cols="8" sm="7">
                  <v-text-field
                    v-model="basicInfo.new_password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    name="password"
                    autocomplete="false"
                    :rules="[
                      validationRules.required,
                      validationRules.spaces,
                      validationRules.password,
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-left" cols="12" sm="10">
                  <v-btn
                    class="main-btn"
                    large
                    @click.prevent="updatePasswordInfo()"
                    >تعديل</v-btn
                  >
                  <v-btn
                    class="cancel-btn mx-4"
                    @click.prevent="cancelUpdatePassword()"
                    large
                    >الغاء</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- ======================= delete dialog ==================== -->
    <v-dialog v-model="deleteDialog" width="30%">
      <v-card id="contact-dialog">
        <v-btn icon @click="deleteDialog = false">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
        <v-card-text class="text-center pa-4 pa-sm-4">
          <p class="second-color message-send">هل تريد حذف الصوره</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#a53860"
            class="white--text"
            @click.prevent="deleteProfileImage"
            :loading="loading"
            >نعم</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import pageHeader from "@/components/pageHeader.vue";
import { validationMixin } from "@/mixins/validationMixin";

export default {
  name: "profileBasicInfo",
  components: { pageHeader },
  mixins: [validationMixin],

  data() {
    return {
      deleteDialog: false,
      show1: false,
      show2: false,
      loading: false,
      updateBasicInfo: false,
      updateMobile: false,
      updatePassword: false,
      valid: false,
      basicInfo: {
        name: "",
        email: "",
        password: "",
        mobile: "",
        new_password: "",
        image: "",
      },
      originalBasicInfo: {
        name: "",
        email: "",
      },
      validationError: "",
      attachImgError: "",

      Codes: [],
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (!this.$route.query.edit) {
          this.updateBasicInfo = false;
          this.updateMobile = false;
          this.updatePassword = false;
        }
      },
    },
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    makeUpdateBasicInfo() {
      this.$router.push("/profile?edit=true");
      this.updateBasicInfo = true;
      this.validationError = "";
    },
    cancelUpdateBasicInfo() {
      this.basicInfo = { ...this.originalBasicInfo };
      this.updateBasicInfo = false;
      this.validationError = "";
    },

    openUpdateMobile() {
      this.$router.push("/profile?edit=true");
      this.updateMobile = true;
      this.validationError = "";
    },
    cancelUpdateMobile() {
      this.basicInfo = { ...this.originalBasicInfo };
      this.updateMobile = false;
      this.validationError = "";
    },
    openUpdatePassword() {
      this.$router.push("/profile?edit=true");
      this.updatePassword = true;
      this.validationError = "";
    },
    cancelUpdatePassword() {
      this.basicInfo = { ...this.originalBasicInfo };
      this.updatePassword = false;
      this.validationError = "";
    },
    getBasicInfo() {
      this.$http
        .get(this.base_url + "/front/getClientBaseInfo", {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.basicInfo = response.data.data;
          this.originalBasicInfo = { ...this.basicInfo };
        });
    },
    getCountryCodes() {
      this.$http.get(this.base_url + "/front/getCodes").then((response) => {
        this.Codes = response.data.data;
      });
    },
    updateInfo() {
      if (this.valid) {
        this.$http
          .post(this.base_url + "/front/updateClientBaseInfo", this.basicInfo, {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            if (response.data.status.error == true) {
              this.validationError = response.data.status.message;
            } else {
              this.originalBasicInfo = { ...this.basicInfo };
              this.updateBasicInfo = false;
            }
          });
      } else {
        this.validate();
      }
    },
    updateMobileInfo() {
      if (this.basicInfo.mobile != this.originalBasicInfo.mobile) {
        if (this.valid) {
          this.$http
            .post(this.base_url + "/front/changeClientMobile", this.basicInfo, {
              headers: {
                locale: localStorage.locale,
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == true) {
                this.validationError = response.data.status.message;
              } else {
                this.updateMobile = false;
                this.logout();
                window.location.href = "/login";
              }
            });
        } else {
          this.validate();
        }
      } else {
        this.updateMobile = false;
      }
    },
    updatePasswordInfo() {
      if (this.basicInfo.password != this.basicInfo.new_password) {
        if (this.valid) {
          this.$http
            .post(
              this.base_url + "/front/changeClientPassword",
              this.basicInfo,
              {
                headers: {
                  locale: localStorage.locale,
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == true) {
                this.validationError = response.data.status.message;
              } else {
                this.updatePassword = false;
                this.logout();
                window.location.href = "/login";
              }
            });
        } else {
          this.validate();
        }
      } else {
        this.validationError = "كلمة المرور القديمة والجديدة متطابقان";
      }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("type");
      localStorage.removeItem("email");
      localStorage.removeItem("phone");
    },

    profileSelectFile() {
      document.getElementById("avatar").click();
    },
    onAddFile(File) {
      this.loading = true;
      if (File) {
        const selectedImage = File;
        const data = new FormData();
        console.log(selectedImage);
        if (
          selectedImage &&
          selectedImage.type &&
          !selectedImage.type.startsWith("image")
        ) {
          this.attachImgError = "مسموح بالصور فقط";
          this.loading = false;
        } else if (selectedImage && selectedImage.size > 3 * 1024 * 1024) {
          this.loading = false;
          this.attachImgError = "الحد الاقصى لحجم الصوره 3 ميجا بايت";
        } else {
          this.attachImgError = "";
          data.set("image", selectedImage);
          this.$http
            .post(this.base_url + "/front/uploadeClientImage", data, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                locale: localStorage.locale,
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.status.error == false) {
                this.basicInfo.image = response.data.data.path;
                localStorage.setItem("profile_image", this.basicInfo.image);
                location.reload();
              }
            });
        }
      }
    },

    deleteProfileImage() {
      this.loading = true;
      this.$http
        .get(this.base_url + "/front/removeClientImage", {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loading = false;
          this.deleteDialog = false;
          if (response.data.status.error == false) {
            this.basicInfo.image = "";
            this.originalBasicInfo.image = "";
            localStorage.removeItem("profile_image");
            location.reload();
          }
        });
    },
  },
  mounted() {
    this.getCountryCodes();
    this.getBasicInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
label {
  font-size: 1.2rem !important;
}
.basicInfo {
  margin: 50px 0;
  .head {
    .main-title {
      color: $user-main-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $user-main-color;
      margin-right: -3px !important;
    }
  }
  .fields {
    label {
      width: 40%;
      font-size: 1.2rem !important;
    }
    .field {
      margin-bottom: 20px;
      .text {
        color: #000000;
        width: 60%;
        font-size: 1.2rem !important;
      }
      .mobile-num {
        direction: ltr !important;
        display: inline-block;
      }
    }

    .profile-img {
      margin-top: 5rem;
      position: relative;
    }
    .profile-img img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .update-btn {
      letter-spacing: 0;
      width: 150px;
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .edit-mode {
    .label-div {
      width: 20%;
    }
    .field-input {
      width: 50%;
    }
    .btn-div {
      width: 30%;
    }

    .save-btn,
    .cancel-btn {
      width: 150px;
    }
  }
}

.fields .profile-img .v-icon.mdi-account {
  font-size: 120px !important;
  background-color: $user-main-color;

  color: #ffffff;
  border-radius: 50%;
}

.profile-img .top-icon {
  position: absolute;
  left: 30%;
  top: 0;
}
.profile-img .bottom-icon {
  position: absolute;
  left: 30%;
  bottom: -14px;
}

@media (max-width: 600px) {
  .basicInfo .fields .field {
    flex-direction: column;
  }
  .basicInfo .fields .field label {
    width: 100%;
  }
  .basicInfo .fields .field .text {
    width: 100%;
  }
}
</style>
